/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    width: 100%;
    height: 200px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    transition: transform 0.8s;
    transform-style: preserve-3d;
    box-shadow: 1px 1px 2px 2px rgba(62, 56, 89, 0.2);
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  /* .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  } */

  .flip-card:hover .flip-card-inner {
    box-shadow: 2px 2px 4px 4px rgba(62, 56, 89, 0.2);
  } 

  .isFlipped {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
  }
  
  /* Style the back side */
  .flip-card-back {
    transform: rotateY(180deg);
  }